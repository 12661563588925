// @ts-nocheck
import React, { useEffect, useState } from 'react';
import image1 from "../assets/images/slide-icon1.png";
import image2 from "../assets/images/slide-icon2.png";
import image3 from "../assets/images/slide-icon3.png";
import image4 from "../assets/images/slide-icon4.png";
import image5 from "../assets/images/slide-icon5.png";


const arrCircle = [
    {
        id: 1, deg: 0, icon: image1,
        title: "Education Industry",
        disc: "Our team of top e-learning software developers specializes in creating custom LMS software tailored to manage, track, and achieve your educational goals. We work closely with clients to develop solutions that enhance learning experiences, drive student success, and boost their online presence."
    },
    {
        id: 2, deg: 72, icon: image2,
        title: "Healthcare Industry",
        disc: "Enhance your healthcare operations with our IT solutions that streamline workflows, improve patient care, and foster innovation. Connect with our experts to boost your online visibility, attract more patients, and expand your brand’s reach globally."
    },
    {
        id: 3, deg: 144, icon: image3,
        title: "E-Commerce Industry",
        disc: "Launch and scale your online store with our tailored IT and digital marketing solutions. From website development to customer engagement strategies, we help you boost your digital presence and increase sales."
    },
    {
        id: 4, deg: 216, icon: image4,
        title: "Travel and Hospitality Industry",
        disc: "Industry: A strong digital presence is crucial for businesses in the travel and hospitality sector. We offer our expertise to enhance your brand presence through custom website and app development and comprehensive digital branding strategies."
    },
    {
        id: 5, deg: 288, icon: image5,
        title: "Real Estate Industry",
        disc: "Connect with our professionals to simplify the buying and selling of properties through our custom real estate management software solutions. Increase your business' presence online and reach potential buyers with our targeted digital marketing solutions."
    },
    {
        id: 6, deg: 302, icon: image5,
        title: "Technology & Software Industry",
        disc: "With years of experience, we provide tailored IT solutions that deliver real results. Our services help you automate tasks and transform digital experiences, setting your business apart in a competitive market."
    },
]

const CircleSlider = () => {
    const [circleArr, setCircleArr] = useState(arrCircle)
    const [rotationDegree, setRotationDegree] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [progressBar, setProgressBar] = useState([1, 2, 3, 4, 5]);


    const handleCircleClick = (item, index) => {

        setRotationDegree(-item?.deg);
        setActiveIndex(index);
    };
    useEffect(() => {
        
        let count = 1
    }, [activeIndex])

    const handleProgressBarClick = (index) => {
        setRotationDegree(-circleArr[index]?.deg);
        setActiveIndex(index);
    };

    useEffect(() => {
        let count = 0
        const animateCircle = () => {
            setRotationDegree((prevDegree) => (prevDegree + 72) % 360);
            if (count == 4) {
                setActiveIndex(0);
                count = 0
            } else {
                setActiveIndex(prevIndex => prevIndex + 1);
                count++
            }
        };

        const intervalId = setInterval(animateCircle, 4000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <section className="services">
            <div className="circle--slider">
                <div className="rotate--circle count5" style={{ transform: `rotate(${rotationDegree}deg)` }}>
                    <ul className="circle--rotate">
                        {circleArr.map((item, index) => (
                            <li className={`block ${index === activeIndex ? 'active' : ''}`} key={index}>
                                <div className="icon" onClick={() => handleCircleClick(item, index)}>
                                    <img src={item?.icon}  alt='icon' />

                                </div>
                            </li>
                        ))}
                    </ul>
                    {/* Add the rest of your JSX for the animated images and navigation buttons here */}
                </div>
                <div className="industrie_content">
                    {circleArr.map((item, index) => (
                        <div className={`ic__item ${index === activeIndex ? 'active' : ''}`} key={index}>
                            <h4>{item?.title}</h4>
                            <p>{item?.disc}</p>
                        </div>
                    ))}
                    <div class="progressBarContainer">
                        {progressBar.map((item, index) => (
                            <div
                                key={index}
                                className={`bar-item ${index === activeIndex ? 'active' : ''}`}
                                onClick={() => handleProgressBarClick(index)}
                            >
                                <span className="progressBar"></span>
                            </div>
                        ))}
                    </div>
                </div>
                {/* ./industrie_content */}
            </div>
        </section>
    );
};

export default CircleSlider;
