// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// img

import AnimatedText from '../components/AnimatedText';
import visionImg from "../assets/images/vision-img.png";

// Page animation
import pageTransition from '../components/pageTransition';

const About = () =>{

  // Client Review Slider
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: false,
    vertical: true,
    centerMode: false,
    verticalSwiping: true,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const renderArrows = () => {
    const handlePrevClick = () => {
      if (sliderRef.current) {
        sliderRef.current.slickPrev();
      }
    };

    const handleNextClick = () => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    };

    return (
      <div className="slider-arrow">

        <div className="sa_prevArrow" onClick={handlePrevClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <g clip-path="url(#clip0_208_349)">
              <path d="M0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5Z" fill="white" />
              <path d="M32.1241 21.408C32.6566 21.408 33.0883 21.8397 33.0883 22.3722C33.0883 22.9048 32.6566 23.3365 32.1241 23.3365V21.408ZM32.1241 23.3365H12.123V21.408H32.1241V23.3365Z" fill="#3D3D3D" />
              <path d="M18.8162 15.4285L11.8733 22.3713L18.8162 29.3142" stroke="#3D3D3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <rect x="-1" y="1" width="43" height="43" rx="21.5" transform="matrix(-1 0 0 1 43 0)" stroke="#3D3D3D" stroke-width="2" />
            <defs>
              <clipPath id="clip0_208_349">
                <rect width="45" height="45" rx="22.5" transform="matrix(-1 0 0 1 45 0)" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className="sa_nextArrow" onClick={handleNextClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <g clip-path="url(#clip0_208_353)">
              <path d="M45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45C34.9264 45 45 34.9264 45 22.5Z" fill="white" />
              <path d="M12.8759 21.408C12.3434 21.408 11.9117 21.8397 11.9117 22.3722C11.9117 22.9048 12.3434 23.3365 12.8759 23.3365V21.408ZM12.8759 23.3365H32.877V21.408H12.8759V23.3365Z" fill="#3D3D3D" />
              <path d="M26.1838 15.4285L33.1267 22.3713L26.1838 29.3142" stroke="#3D3D3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <rect x="1" y="1" width="43" height="43" rx="21.5" stroke="#3D3D3D" stroke-width="2" />
            <defs>
              <clipPath id="clip0_208_353">
                <rect width="45" height="45" rx="22.5" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

      </div>
    );
  };

  return (
    <>
      <section className="gg-page_banner">
        <div className="gg-container">
          <div className="gab_content">
            <h4 className="sub__title">About us</h4>
            <h1 className="gg-title">We’ve cracked the <br />science of excellence.</h1>
          </div>
          {/* ./gab_content */}
        </div>
        {/* ./gg-container */}
      </section>

      <section className="vision_wrapper">
        <div className="gg-container">
          <div className="gg-grid">
            <div className="gg-col-sm-6 gg-col-12">
              <AnimatedText>
                <div className="vw_heading">
                  <h4 className="sub__title">Our Vision</h4>
                  <h2 className="gg-title">Pathway to Future</h2>
                  <p>Our objective is to develop cost-efficient and effortless solutions that aid clients in discovering new avenues for their businesses so that they can attain the zenith of their potential goals. Our trained workforce carries a resolute dedication to optimizing the best resources and cultivating meaningful client relationships.</p>
                </div>
              </AnimatedText>
              {/* ./vw_heading */}
            </div>
            <div className="gg-col-sm-6 gg-col-12">
              <img src={visionImg} alt="vision image" />
            </div>
          </div>
          {/* .gg-grid */}
        </div>
        {/* ./container */}
      </section>

      <section className="whyChoose_wrapper">
        <AnimatedText>
          <div className="vw_heading">
            <h4 className="sub__title">Why Choose us</h4>
            <h2 className="gg-title">Decode our Distinct USP’s</h2>
          </div>
        </AnimatedText>
        {/* ./vw_heading */}
        <div className="wc_grid">
          <div className="wc_item">
            <h3>Expert Consultation</h3>
            <p>With over 50+ happy clients and a trained workforce, we proudly vouch for giving expert consultation from the start to the end of your project.</p>
            <div className="shapeIcon">
              <svg xmlns="http://www.w3.org/2000/svg" width="350" height="351" viewBox="0 0 350 351" fill="none">
                <path d="M87.4999 88.5959H88.0254V88.0704V1.09595H261.974V88.0704V88.5959H262.5H349.474V262.545H262.5H261.974V263.07V350.045H88.0254V263.07V262.545H87.4999H0.525391V88.5959H87.4999Z" stroke="#D5D5D5" />
              </svg>
            </div>
          </div>
          {/* ./wc_item */}
          <div className="wc_item">
            <h3>Data Viz and Storytelling</h3>
            <p>We transform complex narratives into simplified graphic systems to convey impactful stories that stimulate action.</p>
            <div className="shapeIcon">
              <svg xmlns="http://www.w3.org/2000/svg" width="350" height="350" viewBox="0 0 350 350" fill="none">
                <path d="M1.26123 174.57L175 0.831543L348.739 174.57L175 348.309L1.26123 174.57Z" stroke="#D5D5D5" />
              </svg>
            </div>
          </div>
          {/* ./wc_item */}
          <div className="wc_item">
            <h3>All in one</h3>
            <p>Growth Grids can help you with everything from web development to digital marketing. We’re a one stop destination for all your digital needs.</p>
            <div className="shapeIcon">
              <svg xmlns="http://www.w3.org/2000/svg" width="350" height="350" viewBox="0 0 350 350" fill="none">
                <path d="M247.056 348.526H102.947L1.04492 246.623V102.517L102.947 0.615112H247.056L348.955 102.514V246.626L247.056 348.526Z" stroke="#D5D5D5" />
              </svg>
            </div>
          </div>
          {/* ./wc_item */}
          <div className="wc_item">
            <h3>Solution-Oriented Team</h3>
            <p>Roadblocks are part of any journey, but we at Growth Grid believe in outgrowing them and coming up with solutions catered to your unique needs.</p>
            <div className="shapeIcon">
              <svg xmlns="http://www.w3.org/2000/svg" width="350" height="351" viewBox="0 0 350 351" fill="none">
                <path d="M319.603 175.198L319.231 175.57L319.603 175.943C359.431 215.77 359.431 280.345 319.603 320.174C279.775 360.001 215.199 360.001 175.372 320.174L175 319.802L174.628 320.174C134.8 360.001 70.2252 360.001 30.3967 320.174C-9.4317 280.345 -9.43172 215.77 30.3967 175.943L30.7683 175.57L30.3967 175.198C-9.4317 135.371 -9.4317 70.7958 30.3967 30.9672C70.2252 -8.86114 134.8 -8.86116 174.628 30.9672L175 31.3389L175.372 30.9672C215.199 -8.86115 279.775 -8.86114 319.603 30.9672C359.431 70.7958 359.431 135.371 319.603 175.198Z" stroke="#D5D5D5" />
              </svg>
            </div>
          </div>
          {/* ./wc_item */}
        </div>
        {/* ./wc_grid */}
      </section>

      <section className="whoWeAre_wrapper">
        <div className="gg-container">
          <div className="wa_content">
            <AnimatedText>
              <h2 className="gg-title">Who we are?</h2>
              <p>Growth Grids is the powerhouse of innovation, collaboration and productive environment. Our Team of experts is dedicated round the clock in understanding the intricacies of challenges and curate tailor made solutions just for you. We believe in incorporating latest technologies that take your business ahead of time. With over 15+ years of experience, we take pride in helping our clients to sail through the competent digital domain.</p>
            </AnimatedText>
          </div>
          {/* ./wa_content */}
        </div>
      </section>

      <section className="values_wrapper">
        <div className="gg-container">
          <AnimatedText>
            <div className="vw_heading">
              <h4 className="sub__title">Our Values</h4>
              <h2 className="gg-title">Guiding Principles</h2>
              <p>Here are a few reasons why we believe we're the best partner for your next project:</p>
            </div>
          </AnimatedText>
          {/* ./vw_heading */}
          <div className="wc_grid">
            <div className="wc_item">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="61" viewBox="0 0 60 61" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5024 3.22713C19.4066 2.88995 24.1468 2.71313 30.0107 2.71313C31.9461 2.71313 33.7601 2.73239 35.499 2.7704C37.6571 2.81755 39.7721 3.53354 41.5075 4.87287C46.6335 8.82907 49.7732 12.1713 53.6462 17.4476C54.9006 19.1564 55.5808 21.2058 55.6339 23.303C55.6944 25.699 55.7248 28.1228 55.7248 30.5703C55.7248 36.6132 55.5371 42.5121 55.1796 48.2069C54.8531 53.4153 50.7174 57.5562 45.5188 57.9136C40.6148 58.2503 35.8746 58.4273 30.0107 58.4273C26.6524 58.4273 23.6627 58.3696 20.7998 58.2563C22.4944 53.4186 23.8952 50.3916 25.4434 47.9753C26.9814 45.575 28.8913 43.4226 32.0218 40.583C35.5279 37.4024 35.7919 31.9818 32.6113 28.4755C29.4308 24.9693 24.0099 24.7053 20.5039 27.886C16.7183 31.3199 13.6434 34.6156 11.0089 38.7276C10.6026 39.362 10.2123 40.0068 9.83572 40.6647C8.23201 39.5099 6.31535 38.9751 4.42119 39.0657C4.33865 36.2706 4.29639 33.4366 4.29639 30.5703C4.29639 24.5273 4.48415 18.6285 4.84158 12.9339C5.16849 7.72537 9.30417 3.58452 14.5024 3.22713Z" fill="#FFCC29" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.7078 27.4436C52.0272 22.3303 45.9471 21.4891 40.9997 21.5234C37.4494 21.548 34.5786 18.9317 34.5786 15.3813V2.75293C34.8878 2.75844 35.1944 2.76452 35.4987 2.77115C37.6568 2.81833 39.7718 3.5343 41.5071 4.87365C46.6332 8.82984 49.7729 12.172 53.6459 17.4483C54.9004 19.1572 55.5805 21.2066 55.6335 23.3038C55.6679 24.6749 55.6928 26.0549 55.7078 27.4436Z" fill="#AE8500" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.6431 32.0751C29.836 33.3898 29.737 35.4225 28.4221 36.6154C25.087 39.6407 22.813 42.1502 20.9324 45.0857C19.039 48.0411 17.4563 51.5648 15.6617 56.7201C15.2951 57.7731 14.4087 58.5613 13.3198 58.8017C12.2308 59.0425 11.0949 58.7014 10.3186 57.9008L2.52638 49.8651C1.29058 48.5905 1.32189 46.5557 2.5963 45.3201C3.87072 44.0841 5.90566 44.1154 7.14143 45.39L11.3967 49.7781C12.6644 46.6594 13.977 44.025 15.5194 41.6177C17.8112 38.0406 20.5221 35.1023 24.1028 31.8538C25.4177 30.6611 27.4504 30.7601 28.6431 32.0751Z" fill="#AE8500" />
              </svg>
              <h3>Passionate Experts</h3>
              <p>Our team is made up of talented and experienced professionals who are passionate about helping our clients bring their ideas to life.</p>
            </div>
            {/* ./wc_item */}
            <div className="wc_item">
              <svg xmlns="http://www.w3.org/2000/svg" width="70" height="71" viewBox="0 0 70 71" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M38.6214 66.5717H31.3797C28.6993 66.5717 26.4941 64.4592 26.4182 61.7802C26.1181 51.1837 26.1264 37.6962 26.4434 27.2612C26.5136 24.951 28.1787 22.9755 30.4725 22.6937C33.6013 22.3095 36.3998 22.3095 39.5286 22.6937C41.8224 22.9755 43.4875 24.951 43.5577 27.2612C43.8745 37.6962 43.883 51.1837 43.5829 61.7802C43.507 64.4592 41.3018 66.5717 38.6214 66.5717Z" fill="#F6B7D5" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.2673 66.5721H63.5088C66.1892 66.5721 68.3898 64.4721 68.4532 61.7926C68.7748 48.2126 68.7678 22.4773 68.4322 9.42309C68.3727 7.11268 66.7098 5.13852 64.4158 4.8568C61.2872 4.47254 58.4887 4.47254 55.3602 4.8568C53.0661 5.13852 51.4032 7.11268 51.3437 9.42309C51.0081 22.4773 51.0011 48.2126 51.3227 61.7926C51.3861 64.4721 53.5868 66.5721 56.2673 66.5721ZM14.7371 66.5721H7.24038C4.61201 66.5721 2.43079 64.5373 2.33623 61.9101C2.13153 56.2248 2.13813 49.3735 2.35602 43.7628C2.44921 41.3627 4.29774 39.3999 6.69368 39.2292C9.65153 39.0186 12.326 39.0186 15.2838 39.2292C17.6798 39.3999 19.5283 41.3627 19.6215 43.7628C19.8394 49.3735 19.846 56.2248 19.6413 61.9101C19.5467 64.5373 17.3655 66.5721 14.7371 66.5721Z" fill="#E74694" />
              </svg>
              <h3>Results-Driven Solutions</h3>
              <p>Our solutions are designed to deliver real, measurable results that help our clients achieve their goals.</p>
            </div>
            {/* ./wc_item */}
            <div className="wc_item">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="61" viewBox="0 0 60 61" fill="none">
                <g clip-path="url(#clip0_391_810)">
                  <mask id="mask0_391_810" maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="61">
                    <path d="M60 0.570312H0V60.5703H60V0.570312Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_391_810)">
                    <path d="M19.9288 0.570306C19.2906 0.570306 18.7232 0.71215 18.0849 0.995838C15.5317 1.98875 14.4678 4.40009 13.5458 6.45684C12.6239 8.51356 11.7728 10.4994 10.9927 11.2795C10.2125 12.0597 8.22669 12.8398 6.16997 13.6909C4.11322 14.5419 1.70188 15.6058 0.638047 18.088C-0.425783 20.5704 0.496203 23.0525 1.34727 25.1803C2.12741 27.3078 2.97847 29.2936 2.90754 30.3575C2.90754 31.4214 2.05648 33.4072 1.20542 35.5347C0.354359 37.6625 -0.638549 40.0739 0.425281 42.5561C1.41819 45.1093 3.82954 46.1731 5.88628 47.0951C7.94301 48.0172 9.92882 48.8683 10.638 49.6483C11.4182 50.4284 12.1983 52.4142 13.1203 54.4709C13.9714 56.5278 15.0352 58.9391 17.5175 60.0029C19.9997 61.0668 22.482 60.1447 24.5387 59.2936C26.6664 58.5136 28.6523 57.7334 29.7161 57.7334C30.7798 57.7334 32.7656 58.5845 34.8934 59.3647C37.0209 60.2158 39.4324 61.2086 41.9147 60.1447C44.4679 59.1519 45.5317 56.7404 46.3828 54.6838C47.3047 52.6271 48.1558 50.6413 48.936 49.8611C49.716 49.0809 51.7018 48.3009 53.7587 47.3787C55.8154 46.5277 58.2266 45.464 59.2905 42.9817C60.3544 40.4994 59.4324 38.0171 58.5814 35.9604C57.8012 33.8326 56.9501 31.8468 57.021 30.7831C57.0919 29.7193 57.8721 27.7334 58.7231 25.6057C59.5742 23.4781 60.5672 21.0667 59.5033 18.5845C58.5103 16.0313 56.0991 14.9675 54.0424 14.0455C51.9855 13.1235 49.9997 12.2724 49.2906 11.4923C48.5812 10.7121 47.7302 8.72634 46.8083 6.66959C45.9572 4.61285 44.8933 2.20151 42.4112 1.13768C39.9289 0.0738518 37.4466 0.995838 35.3899 1.8469C33.2621 2.62704 31.2763 3.4781 30.2124 3.4781C29.1488 3.4781 27.1629 2.62704 25.1061 1.77598C23.5459 1.13768 21.7729 0.428462 19.9288 0.570306ZM29.9998 12.7689C39.7869 12.7689 47.8013 20.783 47.8013 30.5703C47.8013 40.3576 39.7869 48.3717 29.9998 48.3717C20.2125 48.3717 12.1983 40.3576 12.1983 30.5703C12.1983 20.783 20.2125 12.7689 29.9998 12.7689Z" fill="#00F0FF" />
                    <path d="M30.0001 16.5278C22.1986 16.5278 15.9575 22.7689 15.9575 30.5704C15.9575 38.3719 22.1986 44.613 30.0001 44.613C37.8016 44.613 44.0427 38.3719 44.0427 30.5704C44.0427 22.7689 37.8016 16.5278 30.0001 16.5278ZM36.454 24.7547C37.5179 24.7547 38.2979 25.6768 38.2979 26.6696C38.2979 27.1661 38.0142 27.6626 37.6596 28.0172L29.3618 35.3931C28.5816 36.1022 27.376 36.0313 26.7378 35.2511L23.1207 31.0669C22.4114 30.3576 22.3405 29.1519 23.1207 28.4426C23.8298 27.7335 25.0354 27.6626 25.7448 28.4426C25.8156 28.4426 25.8867 28.5846 25.9576 28.6554L28.2979 31.3506L35.1773 25.2512C35.1773 25.2512 35.8866 24.8258 36.3121 24.7547C36.3121 24.7547 36.454 24.7547 36.5249 24.7547H36.454Z" fill="#02AFBA" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_391_810">
                    <rect width="60" height="60" fill="white" transform="translate(0 0.570312)" />
                  </clipPath>
                </defs>
              </svg>
              <h3>Reliable and Responsive</h3>
              <p>We know that your time is valuable, and we are committed to delivering your  projects on time with utmost professionalism.</p>
            </div>
            {/* ./wc_item */}
            <div className="wc_item">
              <svg xmlns="http://www.w3.org/2000/svg" width="70" height="71" viewBox="0 0 70 71" fill="none">
                <g clip-path="url(#clip0_388_791)">
                  <mask id="mask0_388_791" maskUnits="userSpaceOnUse" x="0" y="0" width="70" height="71">
                    <path d="M70 0.570312H0V70.5703H70V0.570312Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_388_791)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M35 70.5703C54.3302 70.5703 70 54.9003 70 35.5703C70 16.2403 54.3302 0.570312 35 0.570312C15.6698 0.570312 0 16.2403 0 35.5703C0 54.9003 15.6698 70.5703 35 70.5703ZM35 59.9642C48.472 59.9642 59.3939 49.0428 59.3939 35.5703C59.3939 22.0979 48.472 11.1764 35 11.1764C21.528 11.1764 10.6061 22.0979 10.6061 35.5703C10.6061 49.0428 21.528 59.9642 35 59.9642Z" fill="#3CF7A2" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.9999 59.9641C48.4719 59.9641 59.3938 49.0427 59.3938 35.5702C59.3938 22.0978 48.4719 11.1763 34.9999 11.1763C21.5279 11.1763 10.606 22.0978 10.606 35.5702C10.606 49.0427 21.5279 59.9641 34.9999 59.9641ZM34.9999 49.3581C42.6146 49.3581 48.7878 43.1851 48.7878 35.5702C48.7878 27.9553 42.6146 21.7823 34.9999 21.7823C27.3852 21.7823 21.212 27.9553 21.212 35.5702C21.212 43.1851 27.3852 49.3581 34.9999 49.3581Z" fill="#00B261" />
                    <path d="M34.9998 40.8734C37.9286 40.8734 40.3028 38.4991 40.3028 35.5704C40.3028 32.6416 37.9286 30.2673 34.9998 30.2673C32.071 30.2673 29.6968 32.6416 29.6968 35.5704C29.6968 38.4991 32.071 40.8734 34.9998 40.8734Z" fill="#00B261" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.9998 49.3582C42.6147 49.3582 48.7877 43.1853 48.7877 35.5704C48.7877 27.9554 42.6147 21.7825 34.9998 21.7825C27.3849 21.7825 21.2119 27.9554 21.2119 35.5704C21.2119 43.1853 27.3849 49.3582 34.9998 49.3582ZM34.9998 40.8734C37.9286 40.8734 40.3028 38.4991 40.3028 35.5704C40.3028 32.6416 37.9286 30.2673 34.9998 30.2673C32.071 30.2673 29.6968 32.6416 29.6968 35.5704C29.6968 38.4991 32.071 40.8734 34.9998 40.8734Z" fill="#3CF7A2" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_388_791">
                    <rect width="70" height="70" fill="white" transform="translate(0 0.570312)" />
                  </clipPath>
                </defs>
              </svg>
              <h3>Client-Focused</h3>
              <p>We believe that our clients are our partners. Our commitment lies in nurturing enduring partnerships rooted in trust and shared success.</p>
            </div>
            {/* ./wc_item */}
          </div>
          {/* ./wc_grid */}
        </div>
        {/* ./gg-container */}
      </section>

      <section className="clientReview_wrapper">
        <div className="gg-container">
          <div className="gg-grid">
            <div className="gg-col-sm-6 gg-col-12">
              <Slider
                ref={sliderRef}
                {...settings}
              >
                <div className="crw_block">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" viewBox="0 0 35 28" fill="none">
                    <path d="M14.0236 14.0406V27.7358H0V18.4138C0 14.8845 0.628507 11.6621 1.88552 8.74657C3.14254 5.75432 5.22447 2.8388 8.13131 0L13.3165 3.91294C10.0168 7.44226 8.13131 10.8181 7.65993 14.0406H14.0236ZM35 14.0406V27.7358H20.9764V18.4138C20.9764 14.8845 21.6049 11.6621 22.862 8.74657C24.119 5.75432 26.2009 2.8388 29.1077 0L34.2929 3.91294C30.9933 7.44226 29.1077 10.8181 28.6364 14.0406H35Z" fill="#6C2BD9" />
                  </svg>
                  <p>In a crowded market, Growth Grids shines brightly. Their commitment to staying ahead in technology is evident in the reliability and effectiveness of their IT services. Highly recommended!</p>
                  <div className="crw_name">Amelia Mathis</div>
                </div>
                {/* ./crw_block */}
                <div className="crw_block">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" viewBox="0 0 35 28" fill="none">
                    <path d="M14.0236 14.0406V27.7358H0V18.4138C0 14.8845 0.628507 11.6621 1.88552 8.74657C3.14254 5.75432 5.22447 2.8388 8.13131 0L13.3165 3.91294C10.0168 7.44226 8.13131 10.8181 7.65993 14.0406H14.0236ZM35 14.0406V27.7358H20.9764V18.4138C20.9764 14.8845 21.6049 11.6621 22.862 8.74657C24.119 5.75432 26.2009 2.8388 29.1077 0L34.2929 3.91294C30.9933 7.44226 29.1077 10.8181 28.6364 14.0406H35Z" fill="#6C2BD9" />
                  </svg>
                  <p>Impressed with the agility of Growth Grids' IT solutions. They adapt to our evolving needs effectively, ensuring our technology is always aligned with the latest industry standards.</p>
                  <div className="crw_name">Christopher	Slater</div>
                </div>
                {/* ./crw_block */}
                <div className="crw_block">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" viewBox="0 0 35 28" fill="none">
                    <path d="M14.0236 14.0406V27.7358H0V18.4138C0 14.8845 0.628507 11.6621 1.88552 8.74657C3.14254 5.75432 5.22447 2.8388 8.13131 0L13.3165 3.91294C10.0168 7.44226 8.13131 10.8181 7.65993 14.0406H14.0236ZM35 14.0406V27.7358H20.9764V18.4138C20.9764 14.8845 21.6049 11.6621 22.862 8.74657C24.119 5.75432 26.2009 2.8388 29.1077 0L34.2929 3.91294C30.9933 7.44226 29.1077 10.8181 28.6364 14.0406H35Z" fill="#6C2BD9" />
                  </svg>
                  <p>Our experience with Growth Grids has been phenomenal. Their IT products are not just tools; they're strategic assets that have significantly boosted our efficiency and growth trajectory.</p>
                  <div className="crw_name">Kevin	Campbell</div>
                </div>
                {/* ./crw_block */}
                <div className="crw_block">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" viewBox="0 0 35 28" fill="none">
                    <path d="M14.0236 14.0406V27.7358H0V18.4138C0 14.8845 0.628507 11.6621 1.88552 8.74657C3.14254 5.75432 5.22447 2.8388 8.13131 0L13.3165 3.91294C10.0168 7.44226 8.13131 10.8181 7.65993 14.0406H14.0236ZM35 14.0406V27.7358H20.9764V18.4138C20.9764 14.8845 21.6049 11.6621 22.862 8.74657C24.119 5.75432 26.2009 2.8388 29.1077 0L34.2929 3.91294C30.9933 7.44226 29.1077 10.8181 28.6364 14.0406H35Z" fill="#6C2BD9" />
                  </svg>
                  <p>Growth Grids has been instrumental in our success journey. Their IT solutions are not just products; they're catalysts for business growth. Truly a game-changer!</p>
                  <div className="crw_name">Leah Wilkins</div>
                </div>
                {/* ./crw_block */}
                <div className="crw_block">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" viewBox="0 0 35 28" fill="none">
                    <path d="M14.0236 14.0406V27.7358H0V18.4138C0 14.8845 0.628507 11.6621 1.88552 8.74657C3.14254 5.75432 5.22447 2.8388 8.13131 0L13.3165 3.91294C10.0168 7.44226 8.13131 10.8181 7.65993 14.0406H14.0236ZM35 14.0406V27.7358H20.9764V18.4138C20.9764 14.8845 21.6049 11.6621 22.862 8.74657C24.119 5.75432 26.2009 2.8388 29.1077 0L34.2929 3.91294C30.9933 7.44226 29.1077 10.8181 28.6364 14.0406H35Z" fill="#6C2BD9" />
                  </svg>
                  <p>Kudos to Growth Grids for their forward-thinking approach. Their IT solutions have transformed the way we do business, making us more competitive in a fast-paced market.</p>
                  <div className="crw_name">Sue Hudson</div>
                </div>
                {/* ./crw_block */}
              </Slider>

            </div>
            <div className="gg-col-sm-6 gg-col-12">
              <AnimatedText>
                <div className="vw_heading">
                  <h5 className="sub__title">Testimonials</h5>
                  <h2 className="gg-title">Our customers <br /> love what we do</h2>
                  <p>Curious about the impact we've had on businesses like yours? Delve into the client testimonial section at Growth Grids. It's a compelling narrative of partnerships, growth, and satisfaction, told directly by the clients who have experienced the positive changes our strategies have brought to their organisations</p>
                  {renderArrows()}
                </div>
                {/* ./vw_heading */}
              </AnimatedText>
            </div>
          </div>
          {/* ./gg-grid */}
        </div>
        {/* ./gg-container */}
        <div className="pattern__bottom"></div>
      </section>

      <section className="career_wrapper">
        <div className="gg-container">
          <AnimatedText>
            <div className="vw_heading">
              <h5 className="sub__title">Career</h5>
              <h2 className="gg-title">Join the Innovative Team</h2>
              <p>Proactive individuals, ideas brimming with innovation, flexibility to adapt, and the urge to explore—that’s the only professional pre-requisite that we are looking for. If you feel that your mindset aligns with us, then we are more than happy to welcome you to our team!</p>
              <Link to={'/careers'} className="gg-mainButton">
                Apply now
              </Link>
            </div>
            {/* ./vw_heading */}
          </AnimatedText>
        </div>
        {/* ./gg-container */}
      </section>
    </>
  )
}

export default About
